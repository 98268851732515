@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap);
.footer-container {
  width: 100%;
  height: 400px;
  bottom: 0;
  background: #075e54;
  color: #f2f2f2 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 73px 220px 23px 220px;
  margin-top: 50px;
}

.footer-container p {
  color: #F2F2F2 !important;
}

.disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disclaimer p {
  padding: 0 16px;
  font-size: 12px;
  text-align: center;
}

.disclaimer a {
  margin-bottom: 7px;
  color: #FFF;
}

.disclaimer p:first-of-type {
  margin-bottom: 7px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.slogan {
  flex: 1 1;
  padding-right: 294px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.slogan h1 {
  font-size: 18px;
  font-weight: 700;
}

.slogan h2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

.slogan h1,
.slogan h2 {
  margin-bottom: 15px;
}

.slogan .slogan-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.slogan .slogan-icons .icon-box {
  padding-top: 8px;
  background: #fff;
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: ease .5s;
  transition: ease .5s;
}

.slogan .slogan-icons .icon-box:hover {
  cursor: pointer;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.2);
}

.slogan .slogan-icons .icon-box:first-of-type {
  margin-right: 18px;
}

.about {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.about ul {
  list-style: none;
}

.about li {
  margin-bottom: 10px;
}

.about li.list-title {
  font-size: 18px;
  font-weight: 700;
}

.about .links a,
.about .contact h3 {
  font-size: 14px;
  font-weight: 400;
  color: #f2f2f2;
  -webkit-transition: .5s;
  transition: .5s;
}

.about .links a:hover,
.about .contact a:hover {
  color: #d1d1d1;
}

.about .contact h3 {
  margin-top: 7px;
}

.about .contact p,
.about .contact a {
  font-size: 14px;
  font-weight: 400;
  color: #f2f2f2;
  -webkit-transition: .5s;
  transition: .5s;
}

@media (max-width: 1440px) {
  .footer-container {
    padding: 50px 110px 23px 110px;
  }

  .slogan {
    padding-right: 147px;
  }

  .about .links {
    margin-right: 72px;
  }
}

@media (max-width: 1300px) {
  .footer-container {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .footer-container {
    padding: 73px 24px 23px 24px;
  }

  .slogan {
    padding-right: 73px;
  }
}

@media (max-width: 425px) {
  .footer-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 73px 0 23px 0;
  }

  .content {
    flex-direction: column;
    justify-content: flex-start;
  }

  .slogan {
    padding: 0;
    align-items: center;
    margin-bottom: 60px;
  }

  .slogan h2 {
    text-align: center;
  }

  .about {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .about ul {
    text-align: center;
  }

  .about .links {
    margin-right: 0;
    margin-bottom: 60px;
  }

  .about .contact {
    margin-bottom: 60px;
  }
}

.sidebar {
  width: 250px;
}

.MuiAppBar-colorPrimary {
  background-color: #075e54 !important;
}

#logo_navbar {
  height: 35px;
  cursor: pointer;
}

.MuiToolbar-gutters {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-end;
}

.sidebar-icon {
  display: none;
}

#options {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#menu-items {
  display: flex;
  align-items: center;
}

#menu-items a {
  margin: 0 10px;
  font-size: 14px;
  text-decoration: none;
  color: #FFF;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#menu-items a:hover {
  color: #D1D1D1;
}

.sidebar-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  padding-left: 35px;
}

.sidebar-item a {
  text-decoration: none;
  color: #075e54;
  font-size: 18px;
}

@media screen and (max-width: 750px) {

  .MuiToolbar-gutters {
    padding: 0 25px !important;
  }

  .makeStyles-menuButton-4 {
    margin: 0 !important;
  }

  .MuiIconButton-root {
    padding: 0 !important;
  }

  .sidebar-icon {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #options {
    display: none;
  }
}
.link-container {
  text-align: center;
  padding-top: 8px;
  width: 160px;
  height: 40px;
  border: none;
  border-radius: 65px;
  font-weight: 500;
  background-image: linear-gradient(133deg, rgba(6,171,152,1), rgba(7,94,84,1), rgba(6,171,152,1));
  background-size: 200% 100%;
  color: #FFF;
  -webkit-transition: ease 0.6s;
  transition: ease 0.6s;
}

.link-container:hover {
  background-position: 100% 0;
  cursor: pointer;
}
#title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #232d37;
}

#title-container h3 {
  font-weight: 500;
  font-size: 24px;
}

#title-container h1 {
  font-weight: 700;
  font-size: 36px;
}

#title-container div {
  margin-top: 10px;
  margin-bottom: 25px;
  height: 5px;
  width: 100px;
  border-radius: 50px;
  background: rgb(6,171,152);
  background: linear-gradient(133deg, rgba(6,171,152,1) 0%, rgba(7,94,84,1) 100%);
}

@media (max-width: 800px) {
  #title-container h3 {
    font-size: 18px;
  }

  #title-container h1 {
    font-size: 28px;
    text-align: center;
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  width: 100%;
}

.slick-dots {
  bottom: -60px;
}

#home-mobile {
  display: none;
}

#home-mobile h5 {
  text-align: center;
  color: #075e54;
  font-weight: 400;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 25px;
}

#home-left-side {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
}

.carousel-item .carousel-left-side h5 {
  text-align: center;
  color: #075e54;
  font-weight: 400;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 25px;
}

#home-right-side {
  width: 60%;
}

#home-left-side img {
  width: 250px;
}

#carousel-container {
  width: 100%;
  padding: 100px;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.carousel-left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.carousel-item .carousel-left-side img {
  width: 350px;
}

.carousel-item .image {
  width: 60%;
}

.carousel-item .image img {
  width: 100%;
  border-radius: 50px;
}

.carousel-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

#card-home {
  display: flex;
  max-width: 1100px;
  padding: 25px 0;
  border-radius: 50px;
  background-color: #fff;
  margin-bottom: 50px;
  -webkit-transition: ease 0.5s;
  transition: ease 0.5s;
}

#card-home:hover {
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

#card-home .center {
  border-left: 1px solid rgba(0,0,0,0.1);
  border-right: 1px solid rgba(0,0,0,0.1);
}

.card-home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
  padding: 30px;
}

.card-home-wrapper img {
  height: 55px;
}

.card-home-wrapper h4 {
  font-size: 20px;
  color: #075e54;
}

.card-home-wrapper p {
  font-size: 14px;
  color: rgb(182, 201, 219);
  text-align: left;
  margin-top: 15px;
}

#history-section {
  display: flex;
  width: 90%;
  margin: 100px 0;
}

#history-section .left-side {
  width: 50%;
}

#history-section .left-side h2 {
  font-size: 36px;
  color: #232d37;
}

#history-section .left-side p {
  text-align: justify;
}

#history-section .left-side .underline {
  height: 5px;
  width: 100px;
  background: rgb(6,171,152);
  background: linear-gradient(133deg, rgba(6,171,152,1) 0%, rgba(7,94,84,1) 100%);
  border-radius: 5px;
  margin: 5px 0 25px 0;
}

#history-section .right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

#history-section .left-side img {
  width: 100%;
}

#equipments-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.gliceroquimica img {
  border-radius: 0 100px 100px 0;
}

#equipments-section .right-side {
  width: 45%;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#equipments-section .left-side {
  width: 55%;
}

#equipments-section .left-side img {
  width: 100%;
}

#emission-section {
  margin: 75px 0;
  display: flex;
  justify-content: space-between;
}

#emission-section .left-side {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#emission-section .left-side p {
  text-align: justify;
}

#emission-section .right-side {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#emission-section .right-side img {
  width: 100%;
  border-radius: 120px 0px 0px 120px;
}

#gliceroquimica-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#gliceroquimica-section .left-side {
  width: 55%;
}

#gliceroquimica-section .left-side img,
#equipments-section .left-side img {
  width: 100%;
  border-radius: 0 120px 120px 0;
}

#gliceroquimica-section .right-side {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#gliceroquimica-section .right-side p {
  text-align: justify;
}

.paragraph-wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paragraph-wrapper p {
  margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  #carousel-container {
    display: none;
  }

  #home-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
  }

  #home-mobile img {
    width: 100%;
  }

  #home-mobile h5 {
    text-align: center;
    margin: 15px 0;
  }
}

@media screen and (max-width: 1000px) {
  #home-right-side {
    display: none;
  }

  #home-left-side {
    width: 100%;
  }
}

@media (max-width: 1000px) {

  #history-section .left-side h2,
  #equipments-section .right-side h1,
  #emission-section .left-side h1,
  #gliceroquimica-section .right-side h1 {
    font-size: 24px;
  }

  #history-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #history-section .left-side {
    width: 80%;
  } 

  #history-section .right-side {
    display: none;
  }
}

@media (max-width: 700px) {
  #equipments-section .left-side img,
  #emission-section .right-side img,
  #gliceroquimica-section .left-side img {
    border-radius: 0;
    margin-top: 25px;
  }

  
  #emission-section {
    flex-direction: column;
  }

  #equipments-section ,
  #gliceroquimica-section {
    flex-direction: column-reverse;
  }

  #equipments-section .right-side,
  #equipments-section .left-side,
  #emission-section .right-side,
  #emission-section .left-side,
  #gliceroquimica-section .right-side,
  #gliceroquimica-section .left-side {
    width: 100%;
  }

}

@media (max-width: 800px) {
  #card-home {
    flex-direction: column;
  }

  .card-home-wrapper {
    width: 100%;
    border: none;
  }

  #card-home .center {
    border: none;
  }
}

@media (max-width: 375px) {
  #home-left-side p {
    font-size: 14px;
  }
}
.card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  padding: 10px 10px;
  border-radius: 50px;
  width: 100%;
  margin: 15px 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.card-container:hover {
  box-shadow: 0 11px 10px rgba(0, 0, 0, 0.16);
}

.card-container img {
  width: 96.94px;
  margin-bottom: 11px;
}

.card-container h1 {
  font-size: 29px;
  font-weight: 700;
  text-align: center;
  color: #075e54;
}

.card-container p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 1.5;
  color: #51545f;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-container .left-side {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container .right-side {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.card-container .scrollable {
  overflow-y: auto;
}

@media (max-width: 1000px) {
  .card-container {
    height: 350px;
    flex-direction: column;
  }

  .card-container h1 {
    font-size: 24px;
  }


  .card-container img {
    width: 75px;
  }

  .card-container .left-side {
    width: 100%;
  }

  .card-container .right-side {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .card-container {
    height: 500px;
  }
}
.our-company {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.our-company h1,
.our-company h2 {
  color: #232d37;
}

.our-company h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: -10px;
}

.our-company h1 {
  font-size: 60px;
  font-weight: 700;
}

.our-company span {
  background: #075e54;
  height: 4px;
  width: 100px;
  margin-bottom: 81px;
}

.our-company p {
  color: #51545f;
  font-size: 16px;
  font-weight: 300;
  text-align: justify;
  line-height: 1.5;
}

.our-company p:first-of-type {
  margin-bottom: 30px;
}

.pillars {
  width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 70px;
}

.pillars .pillar {
  height: 350px;
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pillars .pillar .circle {
  width: 170px;
  height: 170px;
  background: #075e54;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pillars .pillar .circle img {
  width: 100px;
  height: 150px;
}

.pillars .pillar .pillar-description {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  height: 100px;
}

.pillars .pillar h3 {
  color: #232d37;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

.pillars .pillar p {
  color: #51545f;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 1.5;
}

.values,
.our-team,
.our-partners {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
}

.values h2,
.our-team h2,
.our-partners h2,
.our-clients h2 {
  color: #232d37;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
}

.values h4,
.our-team h4,
.our-partners h4,
.our-clients h4 {
  color: #51545f;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 25px;
}

.team-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 1100px;
}

.member-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.member-container img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 48px;
}

.member-container h2 {
  color: #232d37;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.member-container span {
  background: #075e54;
  height: 2px;
  width: 250px;
  margin-bottom: 30px;
}

.member-container h5 {
  color: #232d37;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}

.member-container p {
  color: #232d37;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 300px;
  line-height: 1.5;
  margin-bottom: 37px;
}

.member-container .social-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 158px;
}

.member-container .social-container a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #232d37;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: .5s;
  transition: .5s;
}

.member-container .social-container a:hover {
  color: #075e54;
}

.member-container .social-container a img {
  width: 30px;
  height: 30px;
  border-radius: 0;
  margin-bottom: 10px;
}

.partners-container,
.clients-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1110px;
}

.partners-container img,
.clients-container img {
  width: 200px;
  margin: 0 50px;
}

.our-clients {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.values-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}


@media (max-width: 1440px) {
  .partners-container img,
  .clients-container img {
    max-width: 300px;
  }

  .team-container .member-container {
    margin-bottom: 100px;
  }
}

@media (max-width: 1300px) {
  .partners-container img {
    width: 200px;
  }
}

@media (max-width: 1200px) {
  .pillars {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 1050px) {
  .our-team .team-container {
    flex-direction: column;
    width: 100%;
  }

  .our-team .team-container .member-container {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .our-company,
  .values,
  .pillars,
  .our-team,
  .our-partners {
    margin-bottom: 100px !important;
    width: 100%;
  }

  .our-clients {
    width: 100%;
  }

  .values-container {
    align-items: center;
  }

  .pillars .pillar {
    height: 375px;
    width: 200px;
  }

  .values h4,
  .our-team h4,
  .our-partners h4,
  .our-clients h4 {
    margin-bottom: 53px;
  }

  .partners-container,
  .clients-container {
    flex-direction: column;
    width: 100%;
  }

  .partners-container img,
  .clients-container img {
    max-width: 250px;
    margin: 50px 0 50px 0;
  }

  .values-container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {  
  .values,
  .our-company,
  .pillars,
  .our-team,
  .our-partners {
    margin-bottom: 100px !important;
  }

  .pillars .pillar .circle {
    width: 180px;
    height: 180px;
  }

  .team-container {
    flex-direction: column;
    align-items: center;
  }

  .member-container img {
    width: 235px;
    height: 235px;
    margin-bottom: 24px;
  }
  
  .member-container h2 {
    font-size: 24px;
    min-height: auto !important;
  }

  .member-container span {
    margin-bottom: 30px;
  }
  
  .member-container h5 {
    margin-bottom: 3px;
  }
  
  .member-container p {
    max-width: 600px;
    line-height: 1.5;
    margin-bottom: 18px;
  }
  
  .member-container .social-container {
    margin-bottom: 53px;
  }
  
  .member-container .social-container a img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }

  .partners-container img,
  .clients-container img {
    max-width: 200px;
  }

  .values-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .pillars {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .pillars .pillar {
    height: 360px;
    margin-bottom: 53px;
  }

  .partners-container,
  .clients-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .partners-container img,
  .clients-container img {
    margin-bottom: 53px;
  }

  .card-container p {
    text-align: center;
  }
}

#card-container {
  width: 320px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 45px 15px 0 15px;
  border-radius: 50px;
  background-color: #FFF;
  -webkit-transition: ease 0.7s;
  transition: ease 0.7s;
}

#card-container:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

#card-container h1 {
  text-align: center;
  margin: 15px 0 15px 0;
  color: #075e54;
}

#card-container p {
  font-size: 14px;
}
.our-services {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.our {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: -10px;
  color: #232d37;
}

.services {
  font-size: 60px;
  font-weight: 700;
  color: #232d37;
}

.our-services span {
  background: #075e54;
  height: 4px;
  width: 100px;
  margin-bottom: 81px;
}

.our-services p {
  color: #51545f;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  line-height: 1.5;
}

.our-services p:first-of-type {
  margin-bottom: 30px;
}

.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
}

.card {
  margin-left: 50px;
  margin-right: 50px;
}

.consulting {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.consulting h2 {
  font-size: 36px;
  text-align: center;
}

.consulting h4 {
  font-size: 20px;
  font-weight: 500;
  color: #51545f;
  text-align: center;
  margin-bottom: 40px;
}

.consulting p {
  font-size: 14px;
  color: #51545f;
  text-align:center;
  text-align: justify;
}

.other-services {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}

.card-services:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-services h1 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #075e54;
}

.card-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  background: #fff;
  border-radius: 10px;
  width: 550px;
  height: 110px;
  -webkit-transition: ease .7s;
  transition: ease .7s;
}

@media (max-width: 1440px) {
  main {
    padding: 120px 100px;
  }
}

@media screen and (max-width: 1200px) {
  .services-container {
    grid-template-columns: 1fr;
  }

  .other-services {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1024px) {
  main {
    padding: 90px 60px;
  }

  .our-services,
  .values {
    margin-bottom: 100px !important;
  }

  .values h4 {
    margin-bottom: 53px;
  }

}

@media (max-width: 768px) {
  main {
    padding: 90px 30px;
  }
  
  .values,
  .our-services  {
    margin-bottom: 100px !important;
  }

  .values-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .card-services {
    width: 400px;
  }
}

@media (max-width: 425px) {
  main {
    padding: 90px 16px;
  }

  .our-services p {
    text-align: center;
  }

  .card-services {
    width: 300px;
    height: 150px;
  }
}

#container {
  display: flex;
  justify-content: center;
}

.contact-title-container {
  width: 50%;
}

#contact-title-container h1 {
  text-align: center;
} 

#contact-title-container h3 {
  font-size: 18px;
  color: #232d37;
  text-align: center;
  font-weight: 400;
  margin-bottom: 40px;
}

#contact-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contact-icon {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 0 0;
}

.contact-icon img {
  height: 60px;
}

.contact-item {
  width: 100%;
  display: flex;
  margin: 30px 0;
  padding: 45px;
  border-radius: 50px;
  background-color: #FFF;
  -webkit-transition: ease 0.7s;
  transition: ease 0.7s;
}

.contact-item:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.contact-right-side {
  max-width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact-title h3 {
  color: #232d37
}

.contact-info {
  margin: 5px 0 5px 0;
}

.contact-info p {
  font-size: 14px;
  color: #232d37;
  text-align: justify;
}

@media (max-width: 425px) {
  #contact-container {
    width: 100%;
  }

  .contact-item {
    padding: 25px 45px;
  }
}
#aplicacoes-container {
  max-width: 1100px;
  margin: 50px auto;
}

#product-description {
  max-width: 1100px;
  margin: 50px auto;
  text-align: justify;
}

#aplicacoes-container p {
  text-align: justify;
}

#produtos-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.slick-dots {
  bottom: -35px;
}

#produtos-leftside {
  width: 25%;
  background-color: #FFF;
  border-radius: 25px;
}

#produto-header {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #075e54;;
}

#produto-header strong {
  color: #FFF;
}

.produto-item {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-top: 1px solid #F2F2F2;
  -webkit-transition: ease 0.4s;
  transition: ease 0.4s;
}

.produto-item:hover {
  cursor: pointer;
  color: #075e54;
}

#produtos-carousel {
  width: 45%;
}

#produtos-rightside {
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 25px;
  background-color: #FFF;
}

.rounded-top {
  border-radius: 25px 25px 0 0;
}

.rounded-bottom {
  border-radius: 0 0 25px 25px;
}

.produto-carousel-item {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.produto-carousel-item img {
  width: 100%;
  border-radius: 25px;
}

@media (max-width: 1100px) {

  #aplicacoes-container, #product-description {
    max-width: 750px;
  }

  #produtos-container {
    width: 100%;
    flex-direction: column;
  }

  #produtos-leftside {
    width: 100%;
  }

  #produtos-carousel {
    width: 100%;
    margin: 50px 0;
  }

  #produtos-rightside {
    width: 100%;
  }
}

@media (max-width: 800px) {
  #aplicacoes-container, #product-description {
    max-width: 80%;
  }
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  padding-top: 64px;
}

html, body, #root {
  height: 100%;
}

body {
  background-color: #f7f7f7 !important;
  -webkit-font-smoothing: antialiased;
}

body, button {
  font-family: 'Poppins', sans-serif;
}

p {
  font-size: 14px !important;
  color: #232D37 !important;
}

a {
  text-decoration: none;
}

main {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 50px 0;
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.MuiPaper-elevation4 {
  box-shadow: none !important;
  margin: 0;
}

