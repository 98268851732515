@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  padding-top: 64px;
}

html, body, #root {
  height: 100%;
}

body {
  background-color: #f7f7f7 !important;
  -webkit-font-smoothing: antialiased;
}

body, button {
  font-family: 'Poppins', sans-serif;
}

p {
  font-size: 14px !important;
  color: #232D37 !important;
}

a {
  text-decoration: none;
}

main {
  height: fit-content;
  padding: 50px 0;
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.MuiPaper-elevation4 {
  box-shadow: none !important;
  margin: 0;
}
