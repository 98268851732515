#aplicacoes-container {
  max-width: 1100px;
  margin: 50px auto;
}

#product-description {
  max-width: 1100px;
  margin: 50px auto;
  text-align: justify;
}

#aplicacoes-container p {
  text-align: justify;
}

#produtos-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.slick-dots {
  bottom: -35px;
}

#produtos-leftside {
  width: 25%;
  background-color: #FFF;
  border-radius: 25px;
}

#produto-header {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #075e54;;
}

#produto-header strong {
  color: #FFF;
}

.produto-item {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-top: 1px solid #F2F2F2;
  transition: ease 0.4s;
}

.produto-item:hover {
  cursor: pointer;
  color: #075e54;
}

#produtos-carousel {
  width: 45%;
}

#produtos-rightside {
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 25px;
  background-color: #FFF;
}

.rounded-top {
  border-radius: 25px 25px 0 0;
}

.rounded-bottom {
  border-radius: 0 0 25px 25px;
}

.produto-carousel-item {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.produto-carousel-item img {
  width: 100%;
  border-radius: 25px;
}

@media (max-width: 1100px) {

  #aplicacoes-container, #product-description {
    max-width: 750px;
  }

  #produtos-container {
    width: 100%;
    flex-direction: column;
  }

  #produtos-leftside {
    width: 100%;
  }

  #produtos-carousel {
    width: 100%;
    margin: 50px 0;
  }

  #produtos-rightside {
    width: 100%;
  }
}

@media (max-width: 800px) {
  #aplicacoes-container, #product-description {
    max-width: 80%;
  }
}